import Occupancy from './views/occupancy';
/* eslint-disable no-unused-vars */
import RentServices from './views/rent-services';
import NetSales from './views/net-sales';
import PortfolioNavigation from './views/portfolio-navigation';
import EffortRate from './views/effort-rate';
import Vue from "vue";
import Router from "vue-router";

import auth from "./auth";

import Home from "./views/home";
import Profile from "./views/profile";
import Tasks from "./views/tasks";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";
import Footfall from './views/footfall';
import Portfolio from "@/views/portfolio";
import TenantPage from "@/views/tenant-page";
import Lof from "@/views/lof.vue";


Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/portfolio",
      name: "portfolio",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Lof,
      }
    },
    {
      path: "/home",
      name: "home",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Home
      }
    },
    // {
    //   path: "/portfolio",
    //   name: "portfolio",
    //   meta: {requiresAuth: true},
    //   components: {
    //     layout: defaultLayout,
    //     content: Portfolio
    //   }
    // },
    // {
    //   path: "/profile",
    //   name: "profile",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: defaultLayout,
    //     content: Profile
    //   }
    // },
    // {
    //   path: "/tasks",
    //   name: "tasks",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: defaultLayout,
    //     content: Tasks
    //   }
    // },
    {
      path: "/login-form",
      name: "login-form",
      meta: {requiresAuth: false},
      components: {
        layout: simpleLayout,
        content: () =>
            import(/* webpackChunkName: "login" */ "./views/login-form")
      },
      props: {
        layout: {
          title: "Log In"
        }
      }
    },
    //   {
    //   path: "/login-form",
    //   name: "login-form",
    //   meta: { requiresAuth: false },
    //   components: {
    //     layout: simpleLayout,
    //     content: () =>
    //       import(/* webpackChunkName: "login" */ "./views/login-form")
    //   },
    //   props: {
    //     layout: {
    //       title: "Sign In"
    //     }
    //   }
    // },
    // {
    //   path: "/reset-password",
    //   name: "reset-password",
    //   meta: { requiresAuth: false },
    //   components: {
    //     layout: simpleLayout,
    //     content: () =>
    //       import(/* webpackChunkName: "login" */ "./views/reset-password-form")
    //   },
    //   props: {
    //     layout: {
    //       title: "Reset Password",
    //       description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
    //     }
    //   }
    // },
    // {
    //   path: "/create-account",
    //   name: "create-account",
    //   meta: { requiresAuth: false },
    //   components: {
    //     layout: simpleLayout,
    //     content: () =>
    //       import(/* webpackChunkName: "login" */ "./views/create-account-form")
    //   },
    //   props: {
    //     layout: {
    //       title: "Sign Up"
    //     }
    //   }
    // },
    // {
    //   path: "/change-password/:recoveryCode",
    //   name: "change-password",
    //   meta: { requiresAuth: false },
    //   components: {
    //     layout: simpleLayout,
    //     content: () =>
    //       import(/* webpackChunkName: "login" */ "./views/change-password-form")
    //   },
    //   props: {
    //     layout: {
    //       title: "Change Password"
    //     }
    //   }
    // },
    {
      path: "/",
      redirect: "/portfolio"
    },
    {
      path: "/recovery",
      redirect: "/portfolio"
    },
    // {
    //   path: "*",
    //   redirect: "/home"
    // },
    {
      path: "/footfall",
      name: "footfall",
      meta: { requiresAuth: true },
      components:
      {
        layout: defaultLayout,
        content: Footfall
      }
    },
    {
      path: "/effort-rate",
      name: "effort-rate",
      meta: { requiresAuth: true },
      components:
      {
        layout: defaultLayout,
        content: EffortRate
      }
    },

    {
      path: "/net-sales",
      name: "net-sales",
      meta: { requiresAuth: true },
      components:
      {
        layout: defaultLayout,
        content: NetSales
      }
    },

    {
      path: "/rent-services",
      name: "rent-services",
      meta: { requiresAuth: true },
      components:
      {
        layout: defaultLayout,
        content: RentServices
      }
    },
    {
      path: "/tenants/:tenant",
      name: "tenant-page",
      meta: { requiresAuth: true },
        components:
        {
          layout: defaultLayout,
          content: TenantPage
        }
    },
    {
      path: "/occupancy",
      name: "occupancy",
      meta: { requiresAuth: true },
      components:
      {
        layout: defaultLayout,
        content: Occupancy
      }
    }
  ]
});

router.beforeEach((to, from, next) => {

  if (to.name === "login-form" && auth.loggedIn() && (new Date() < new Date(window.localStorage.getItem("refreshTokenExpiringDate").replace(",", "")))) {
    next({ name: "portfolio" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
