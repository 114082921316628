<template>
  <div class="custom-wrapper">

    <!-- # RESPONSIVE BOX SETTINGS -->

    <DxResponsiveBox :screen-by-width="screenByWidth" single-column-screen="xs" id="responsive-box" width="100%">
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />

      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />

      <!-- # FILTERS -->
      <DxItem class="category-list-selector">
        <DxLocation :row="0" :col="0" :colspan="6" screen="xs sm md lg" />
        <template #default >
          <div class="filterContainer">

            <!-- # STORE FILTER -->
            <div class="storeSelectorContainer">
              <!--                          temp-->
              <DxSelectBox style="font-family: 'Nunito'"
                           :dataSource="getOutletsList.find(item => item.id === 0 )"
                           displayExpr="name"
                           :show-clear-button="false"
                           :placeholder="getOutletsList.find(item => item.id === 0 ).name"
                           value-expr="id"
                           :onValueChanged="onOutletSelected"
                           :disabled="true"
                           styling-mode="underlined"
              />
              <!--                     v-model="selectedOutlet"-->

            </div>

            <div class="filtersContainer">
              <div>
                <!-- # CALENDAR FILTER -->
                <!--              <div class="display-calendar-btn" @click="onShowCalendar">{{ displayStringDate }}</div>-->
                <!--              #n -->
                <div class="display-calendar-btn" @click="onShowCalendar">{{ $store.state.selectedDate ? (new Date($store.state.selectedDate.slice(0,4), ($store.state.selectedDate.slice(4,6) - 1), $store.state.selectedDate.slice(6,8))).toLocaleString('en-us', { month: 'short' }) + " " + $store.state.selectedDate.slice(0,4) : (new Date(String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4), (String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(4,6)), String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(6,8))).toLocaleString('en-us', { month: 'short' }) + " " + String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4) }}</div>
                <!--                            <div class="display-calendar-btn" @click="onShowCalendar">{{ $store.state.selectedDate ? $store.state.selectedDate : $store.state.outletLastSalesDates.salesLastDayClosedMonth }}</div>-->
                <div :class="['calendarContainer', calendarHidden ? 'calendarHidden' : '']">

                  <div class="closeCalendarBtn" @click="onShowCalendar">x</div>
                  <div class="calendarButton">
                    <div class="calendarButtonTitle"></div>
                    <button v-for="(dateBtn, ix) in [{name: 'month', value: 'year'}, {name: 'week', value: 'month'}]" :key="ix"
                            :class="[(dateBtn.name + '-btn'), calendarZoomLevel === dateBtn.value ? 'activeDate' : '', dateBtn.value === 'month' ? 'disabled-btn' : '']"
                            @click="onCalendarZoomLevelSelection(dateBtn)"
                            :disabled="dateBtn.value === 'month'"
                    >{{ dateBtn.name }}</button>
                  </div>
                  <div class="calendarComponentContainer">
                    <div class="calendarTitle">{{ loc.FILTERS.CALENDARREFTITLE }}</div>
                    <DxCalendar
                        @value-changed="onSelectedDate"
                        :zoom-level="calendarZoomLevel"
                        :max-zoom-level="calendarZoomLevel"
                        :max="maxSelectableDate"
                        :min="minSelectableDate"
                        cell-template="customCalendarCellTemplate"
                        :value="$store.state.selectedDate ? `${$store.state.selectedDate.slice(0,4)}-${$store.state.selectedDate.slice(4,6)}-01` : `${('' + $store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4)}-${('' + $store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(4,6)}-01`"
                    >
                      <!--:value="$store.state.selectedDate ? $store.state.selectedDate : `${$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(0,4)}-${$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(4,6)}-01`"-->
                      <!--                    #n-->
                      <!--                        :disabled-dates="disabledDates"-->
                      <!--                        :value="(this.$store.state.selectedDate === null ? this.$store.state.outletLastSalesDates.salesLastDay : this.$store.state.selectedDate)"-->
                      <!--                        v-model="currentValue"-->
                      <!--                        :min="minDateValue"-->
                      <!--                        :max="maxDateValue"-->
                      <!--                        :disabled-dates="disabledDates"-->
                      <!--                        :first-day-of-week="firstDay"-->
                      <!--                        :disabled="disabled"-->
                      <!--                        :cell-template="cellTemplate"-->
                      <!--                        <template #custom="{ data: cell }">-->
                      <!--                          <span :class="getCellCssClass(cell.date)">-->
                      <!--                            {{ cell.text }}-->
                      <!--                          </span>-->
                      <!--                        </template>-->
                      <template #customCalendarCellTemplate="{ data: data, index }">
                        <CustomCalendarCellTemplate :data="data" :cell-index="index" />
                      </template>
                    </DxCalendar>
                  </div>
                </div>

                <!--Settings-->
                <div class="display-settings-btn" @click="onShowSettings"><i class="dx-icon dx-icon-preferences fa-4x"></i></div>
                <div :class="['calendarContainer', settingsHidden ? 'calendarHidden' : '']">
                  <div class="closeCalendarBtn" @click="onShowSettings">x</div>
                  <div class="calendarButton">
                    <div class="calendarButtonTitle"></div>
                    <button v-for="(dateBtn, ix) in [{name: 'year', value: 'year'}]" :key="ix"
                            :class="[(dateBtn.name + '-btn'), calendarZoomLevel === dateBtn.value ? 'activeDate' : '', dateBtn.value === 'month' ? 'disabled-btn' : '']"
                            @click="onCalendarZoomLevelSelection(dateBtn)"
                            :disabled="dateBtn.value === 'year'"
                    >
                      {{ dateBtn.name }}
                    </button>
                  </div>
                  <div class="calendarComponentContainer">
                    <div class="calendarTitle">{{ loc.FILTERS.CALENDARCOMPARISONTITLE }}</div>
                    <DxCalendar
                        @value-changed="onSelectedSetting"
                        :zoom-level="settingsZoomLevel"
                        :max-zoom-level="settingsZoomLevel"
                        :min-zoom-level="settingsZoomLevel"
                        :max="settingsMaxSelectableDate"
                        :min="settingsMinSelectableDate"
                        :value="$store.state.selectedSettings ? `${$store.state.selectedSettings.slice(0,4)}` : `${ '' + (('' + $store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4)-1) }`"
                    >
                    </DxCalendar>
                  </div>
                </div>
              </div>

              <!-- # PRODUCT CATEGORIES FILTER -->
              <div class="categoriesListSelectorContainer" style="width: 350px; font-family: 'Nunito'">
                <DxSelectBox style="font-family: 'Nunito'"
                             :dataSource="getCategoriesList"
                             displayExpr= "description"
                             :placeholder="loc.FILTERS.PRODCATPLACEHOLDER"
                             value-expr="id"
                             :onValueChanged="onProductCategorySelected"
                             :show-clear-button="true"
                             styling-mode="underlined"
                             :value="$store.state.selectedCategory"
                             :disabled="!pageDataReady || productCategoryFilterDisabledByPage"

                />
                <!--                #n-->
                <!--                       v-model="selectedCategory"-->
                <!--                    <DxTextBoxButton name="clear" :options="{ }"/>-->
              </div>

            </div>
          </div>
        </template>
      </DxItem>

      <!-- ############## new row ############## -->

      <!--   FOOTFALL   -->
      <!--   Number of visitors   -->
<!--      #alimentato da footfallSnapshot-->
      <DxItem class="item text" >
        <DxLocation :row="3" :col="0" :colspan="2" screen="lg" />
        <DxLocation :row="3" :col="0" :colspan="3" screen="xs sm md" />
        <template #default >
          <basic-text-visualizer
              :component-title="loc.MAINPANEL.VISITORSTEXTTITLEREF"
              :comparison-date="comparisonDateREF"
              :comparison-value="textVisitors.dataFilteredByProductCategoryREF.visitorsREFPY"
              :ref-date="refDateREF"
              :ref-value="textVisitors.dataFilteredByProductCategoryREF.visitorsREF"
              :trend-value="textVisitors.dataFilteredByProductCategoryREF.varVisitors"
          />
        </template>
      </DxItem>

      <!--      #alimentato da footfallSnapshot footfallTotalsYTD-->
      <DxItem class="item text" >
        <DxLocation :row="3" :col="2" :colspan="2" screen="lg" />
        <DxLocation :row="3" :col="3" :colspan="3" screen="xs sm md" />
        <template #default >
          <basic-text-visualizer
              :component-title="loc.MAINPANEL.VISITORSTEXTTITLE"
              :comparison-date="comparisonDateYTDShort"
              :comparison-value="textVisitors.dataFilteredByProductCategory.visitorsPY"
              :ref-date="refDateYTDShort"
              :ref-value="textVisitors.dataFilteredByProductCategory.visitors"
              :trend-value="textVisitors.dataFilteredByProductCategory.varVisitors"
          />
        </template>
      </DxItem>

      <!--      #alimentato da footfallSnapshot footfallTotalsLTM-->
      <DxItem class="item text" >
        <DxLocation :row="3" :col="4" :colspan="2" screen="lg" />
        <DxLocation :row="4" :col="0" :colspan="3" screen="xs sm md" />
        <template #default >
          <basic-text-visualizer
              :component-title="loc.MAINPANEL.VISITORSTEXTTITLELTM"
              :comparison-date="comparisonDateLTMShort"
              :comparison-value="textVisitors.dataFilteredByProductCategoryLTM.visitorsPY"
              :ref-date="refDateLTMShort"
              :ref-value="textVisitors.dataFilteredByProductCategoryLTM.visitors"
              :trend-value="textVisitors.dataFilteredByProductCategoryLTM.varVisitors"
          />
        </template>
      </DxItem>

      <!--  Conversion Rate    -->
      <!--      #alimentato da footfallSnapshot-->
      <DxItem class="item text" >
        <DxLocation :row="4" :col="0" :colspan="2" screen="lg" />
        <DxLocation :row="4" :col="3" :colspan="3" screen="xs sm md" />
        <template #default >
          <basic-text-visualizer
              :component-title="loc.MAINPANEL.CRTEXTTITLEREF"
              :comparison-date="comparisonDateREF"
              :comparison-value="textVisitors.dataFilteredByProductCategoryREF.crREFPY"
              :ref-date="refDateREF"
              :ref-value="textVisitors.dataFilteredByProductCategoryREF.crREF"
              :trend-value="textVisitors.dataFilteredByProductCategoryREF.varCR"
              :is-percentage="true"
              :is-delta-number="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da footfallSnapshot footfallTotalsYTD-->
      <DxItem class="item text" >
        <DxLocation :row="4" :col="2" :colspan="2" screen="lg" />
        <DxLocation :row="5" :col="0" :colspan="3" screen="xs sm md" />
        <template #default >
          <basic-text-visualizer
              :component-title="loc.MAINPANEL.CRTEXTTITLE"
              :comparison-date="comparisonDateYTDShort"
              :comparison-value="textVisitors.dataFilteredByProductCategory.crPY"
              :ref-date="refDateYTDShort"
              :ref-value="textVisitors.dataFilteredByProductCategory.cr"
              :trend-value="textVisitors.dataFilteredByProductCategory.varCR"
              :is-percentage="true"
              :is-delta-number="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da footfallSnapshot footfallTotalsLTM-->
      <DxItem class="item text" >
        <DxLocation :row="4" :col="4" :colspan="2" screen="lg" />
        <DxLocation :row="5" :col="3" :colspan="3" screen="xs sm md" />
        <template #default >
          <basic-text-visualizer
              :component-title="loc.MAINPANEL.CRTEXTTITLELTM"
              :comparison-date="comparisonDateLTMShort"
              :comparison-value="textVisitors.dataFilteredByProductCategoryLTM.crPY"
              :ref-date="refDateLTMShort"
              :ref-value="textVisitors.dataFilteredByProductCategoryLTM.cr"
              :trend-value="textVisitors.dataFilteredByProductCategoryLTM.varCR"
              :is-percentage="true"
              :is-delta-number="true"
          />
        </template>
      </DxItem>

      <!--Net Sales-->
      <!--      #alimentato da salesSnapshot-->
      <DxItem >
        <DxLocation :row="5" :col="0" :colspan="2" screen="lg" />
        <DxLocation :row="6" :col="0" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.NETSALES"
              :comparisonDate="comparisonDateText"
              :comparisonValue="Math.round(cloumnStatistic.netSales.salesREFPY)"
              :refDate="refDateText"
              :refValue="Math.round(cloumnStatistic.netSales.salesREF)"
              :trendValue="cloumnStatistic.netSales.varSales"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da salesTotalsYTD-->
      <DxItem >
        <DxLocation :row="5" :col="2" :colspan="2" screen="lg" />
        <DxLocation :row="6" :col="3" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.NETSALESYTD"
              :comparisonDate="comparisonDateYTDShort"
              :comparisonValue="Math.round(cloumnStatistic.dataFilteredByProductCategoryYTD.salesPY)"
              :refDate="refDateYTDShort"
              :refValue="Math.round(cloumnStatistic.dataFilteredByProductCategoryYTD.sales)"
              :trendValue="cloumnStatistic.dataFilteredByProductCategoryYTD.varSales"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da salesTotalsLTM-->
      <DxItem >
        <DxLocation :row="5" :col="4" :colspan="2" screen="lg" />
        <DxLocation :row="7" :col="0" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.NETSALESLTM"
              :comparisonDate="comparisonDateLTMShort"
              :comparisonValue="Math.round(cloumnStatistic.dataFilteredByProductCategoryLTM.salesPY)"
              :refDate="refDateLTMShort"
              :refValue="Math.round(cloumnStatistic.dataFilteredByProductCategoryLTM.sales)"
              :trendValue="cloumnStatistic.dataFilteredByProductCategoryLTM.varSales"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!-- Net Sales/SQM -->
      <!--      #alimentato da salesSnapshot-->
      <DxItem >
        <DxLocation :row="6" :col="0" :colspan="2" screen="lg" />
        <DxLocation :row="7" :col="3" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.NETSALESSQM"
              :comparisonDate="comparisonDateText"
              :comparisonValue="cloumnStatistic.netSalesSqm.salesSqmREFPY"
              :refDate="refDateText"
              :refValue="cloumnStatistic.netSalesSqm.salesSqmREF"
              :trendValue="cloumnStatistic.netSalesSqm.varSalesSqm"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da salesTotalsYTD-->
      <DxItem >
        <DxLocation :row="6" :col="2" :colspan="2" screen="lg" />
        <DxLocation :row="8" :col="0" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.NETSALESSQMYTD"
              :comparisonDate="comparisonDateYTDShort"
              :comparisonValue="cloumnStatistic.dataFilteredByProductCategoryYTD.salesSqmPY"
              :refDate="refDateYTDShort"
              :refValue="cloumnStatistic.dataFilteredByProductCategoryYTD.salesSqm"
              :trendValue="cloumnStatistic.dataFilteredByProductCategoryYTD.varSalesSqm"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da salesTotalsLTM-->
      <DxItem >
        <DxLocation :row="6" :col="4" :colspan="2" screen="lg" />
        <DxLocation :row="8" :col="3" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.NETSALESSQMLTM"
              :comparisonDate="comparisonDateLTMShort"
              :comparisonValue="cloumnStatistic.dataFilteredByProductCategoryLTM.salesSqmPY"
              :refDate="refDateLTMShort"
              :refValue="cloumnStatistic.dataFilteredByProductCategoryLTM.salesSqm"
              :trendValue="cloumnStatistic.dataFilteredByProductCategoryLTM.varSalesSqm"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!-- Avg Ticket -->
      <!--      #alimentato da salesSnapshot-->
      <DxItem >
        <DxLocation :row="7" :col="0" :colspan="2" screen="lg" />
        <DxLocation :row="9" :col="0" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.AVGTICKET"
              :comparisonDate="comparisonDateText"
              :comparisonValue="cloumnStatistic.avegTicket.avgTicketREFPY"
              :refDate="refDateText"
              :refValue="cloumnStatistic.avegTicket.avgTicketREF"
              :trendValue="cloumnStatistic.avegTicket.varAvgTicket"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da salesTotalsYTD-->
      <DxItem >
        <DxLocation :row="7" :col="2" :colspan="2" screen="lg" />
        <DxLocation :row="9" :col="3" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.AVGTICKETYTD"
              :comparisonDate="comparisonDateYTDShort"
              :comparisonValue="cloumnStatistic.dataFilteredByProductCategoryYTD.avgTicketPY"
              :refDate="refDateYTDShort"
              :refValue="cloumnStatistic.dataFilteredByProductCategoryYTD.avgTicket"
              :trendValue="cloumnStatistic.dataFilteredByProductCategoryYTD.varAvgTicket"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da salesTotalsLTM-->
      <DxItem >
        <DxLocation :row="7" :col="4" :colspan="2" screen="lg" />
        <DxLocation :row="10" :col="0" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.AVGTICKETLTM"
              :comparisonDate="comparisonDateLTMShort"
              :comparisonValue="cloumnStatistic.dataFilteredByProductCategoryLTM.avgTicketPY"
              :refDate="refDateLTMShort"
              :refValue="cloumnStatistic.dataFilteredByProductCategoryLTM.avgTicket"
              :trendValue="cloumnStatistic.dataFilteredByProductCategoryLTM.varAvgTicket"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!-- Spend per visitor -->
      <!--      #alimentato da salesSnapshot-->
      <DxItem >
        <DxLocation :row="8" :col="0" :colspan="2" screen="lg" />
        <DxLocation :row="10" :col="3" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.SPENDPERVISITOR"
              :comparisonDate="comparisonDateText"
              :comparisonValue="cloumnStatistic.spendPerVisitor.spvREFPY"
              :refDate="refDateText"
              :refValue="cloumnStatistic.spendPerVisitor.spvREF"
              :trendValue="cloumnStatistic.spendPerVisitor.varSPV"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da salesTotalsYTD-->
      <DxItem >
        <DxLocation :row="8" :col="2" :colspan="2" screen="lg" />
        <DxLocation :row="11" :col="0" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.SPENDPERVISITORYTD"
              :comparisonDate="comparisonDateYTDShort"
              :comparisonValue="cloumnStatistic.dataFilteredByProductCategoryYTD.spvPY"
              :refDate="refDateYTDShort"
              :refValue="cloumnStatistic.dataFilteredByProductCategoryYTD.spv"
              :trendValue="cloumnStatistic.dataFilteredByProductCategoryYTD.varSPV"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!--      #alimentato da salesTotalsLTM-->
      <DxItem >
        <DxLocation :row="8" :col="4" :colspan="2" screen="lg" />
        <DxLocation :row="11" :col="3" :colspan="3" screen="xs sm md" />
        <template #default>
          <basic-text-visualizer
              :componentTitle="loc.NETSALES.SPENDPERVISITORLTM"
              :comparisonDate="comparisonDateLTMShort"
              :comparisonValue="cloumnStatistic.dataFilteredByProductCategoryLTM.spvPY"
              :refDate="refDateLTMShort"
              :refValue="cloumnStatistic.dataFilteredByProductCategoryLTM.spv"
              :trendValue="cloumnStatistic.dataFilteredByProductCategoryLTM.varSPV"
              :isCurrency="true"
          />
        </template>
      </DxItem>

      <!-- Conversion Rate -->
      <!--      <DxItem>-->
      <!--        <DxLocation :row="13" :col="0" :colspan="2" screen="xs sm md lg" />-->
      <!--        <template #default>-->
      <!--          <basic-text-visualizer-->
      <!--              :componentTitle="loc.NETSALES.CRRETAILYTD"-->
      <!--              :comparisonDate="comparisonDateYTD"-->
      <!--              :comparisonValue="cloumnStatistic.dataFilteredByProductCategoryYTD.crPY"-->
      <!--              :refDate="refDateYTD"-->
      <!--              :refValue="cloumnStatistic.dataFilteredByProductCategoryYTD.cr"-->
      <!--              :trendValue="cloumnStatistic.dataFilteredByProductCategoryYTD.varCR"-->
      <!--              :isPercentage="true"-->
      <!--          />-->
      <!--        </template>-->
      <!--      </DxItem>-->

      <!--      <DxItem>-->
      <!--        <DxLocation :row="13" :col="2" :colspan="2" screen="xs sm md lg" />-->
      <!--        <template #default>-->
      <!--          <basic-text-visualizer-->
      <!--              :componentTitle="loc.NETSALES.CRRETAILLTM"-->
      <!--              :comparisonDate="comparisonDateLTM"-->
      <!--              :comparisonValue="cloumnStatistic.dataFilteredByProductCategoryLTM.crPY"-->
      <!--              :refDate="refDateLTM"-->
      <!--              :refValue="cloumnStatistic.dataFilteredByProductCategoryLTM.cr"-->
      <!--              :trendValue="cloumnStatistic.dataFilteredByProductCategoryLTM.varCR"-->
      <!--              :isPercentage="true"-->
      <!--          />-->
      <!--        </template>-->
      <!--      </DxItem>-->






      <!-- ############## 1st row ############## -->

      <DxItem class="item doughnut">
        <DxLocation :row="1" :col="0" :colspan="6" screen="xs sm md lg" />
        <template #default >
          <basic-double-doughnut-visualizer :componentTitle="loc.MAINPANEL.BARGAUGETITLE" :comparisonDate="comparisonDateLTM" :refDate="refDateLTM" :dataReady="pageDataReady"
                                            :legend="[
                                                (`ER Good <= ${barGaugeER.legend.goodNormalThreshold}%`),
                                                (`${barGaugeER.legend.goodNormalThreshold}% < ER Normal < ${barGaugeER.legend.normalBadThreshold}%`),
                                                (`ER Bad >= ${barGaugeER.legend.normalBadThreshold}%`),
                                            ]"
          >
            <template v-slot:componentDoughnut1>
              <DxBarGauge
                  @drawn="onDrawn"
                  class="gauge"
                  :start-value="0"
                  :end-value="100"
                  :values="barGaugeER.dataRef"
                  :geometry="barGaugeGeometry"
                  :palette="[colorGood, colorNormal, colorBad]"
                  resolveLabelOverlapping="hide"
                  :label="barGaugesLabelsSettings"
              >
                <!--                <DxLabel-->
                <!--                    :indent="10"-->
                <!--                    :format="format"-->
                <!--                    :customize-text="customizeText"-->
                <!--                    :font="barGaugeLabelFont"-->
                <!--                />-->
                <DxLegend
                    marker-template="customBarGaugeLegendTemplate"
                    :visible="true"
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    :customizeText="customizeBarGaugeLegendText"
                    item-text-position="right"
                    :font="getLegendFontSettings"
                />
                <template #customBarGaugeLegendTemplate="{ data }">
                  <CustomBarGaugeLegendTemplate :item="data"/>
                </template>

              </DxBarGauge>
            </template>

            <template v-slot:componentDoughnut2>
              <DxBarGauge
                  @drawn="onDrawn"
                  class="gauge"
                  :start-value="0"
                  :end-value="100"
                  :values="barGaugeER.dataComparison"
                  :geometry="barGaugeGeometry"
                  :palette="[colorGood, colorNormal, colorBad]"
                  resolveLabelOverlapping="hide"
                  :label="barGaugesLabelsSettings"
              >
                <!--                <DxLabel-->
                <!--                    :indent="30"-->
                <!--                    :format="format"-->
                <!--                    :customize-text="customizeText"-->
                <!--                    :font="barGaugeLabelFont"-->
                <!--                />-->
                <DxLegend
                    marker-template="customBarGaugeLegendTemplate"
                    :visible="true"
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    :customizeText="customizeBarGaugeLegendText"
                    item-text-position="right"
                    :font="getLegendFontSettings"
                />
                <template #customBarGaugeLegendTemplate="{ data }">
                  <CustomBarGaugeLegendTemplate :item="data"/>
                </template>

              </DxBarGauge>
            </template>
          </basic-double-doughnut-visualizer>
        </template>
      </DxItem>



      <!-- ############## 2nd row ############## -->
      <!--      OCCUPANCY -->

      <!-- 1st column -->
      <DxItem class="item text">
        <DxLocation :row="2" :col="0" :colspan="3" screen="xs sm md lg" />
        <template #default >
          <basic-chart-visualizer :component-title="loc.OCCUPANCY.OCCUPANCY" :comparisonDate="comparisonDateTextGauge" :refDate="refDateTextGauge" :legendItemColor1="outletLofStyle.secondaryColor" :legendItemColor2="outletLofStyle.primaryColor">
            <template v-slot:componentDoughnut>
              <DxBarGauge
                  @drawn="onDrawn"
                  class="small-gauge"
                  :start-value="0"
                  :end-value="100"
                  :values="barGaugeEROccupancy.dataRef"
                  :geometry="barGaugeGeometry"
                  :palette="[outletLofStyle.secondaryColor, outletLofStyle.primaryColor]"
                  resolveLabelOverlapping="hide"
                  :label="barGaugesOccupancyLabelsSettings"
              >
                <!--                <DxLabel-->
                <!--                    :indent="0"-->
                <!--                    :format="formatOccupancy"-->
                <!--                    :customize-text="customizeText"-->
                <!--                    :font="barGaugeLabelFont"-->
                <!--                />-->
              </DxBarGauge>
            </template>
          </basic-chart-visualizer>
        </template>
      </DxItem>
      <!-- 2st column -->
      <DxItem class="item text">
        <DxLocation :row="2" :col="3" :colspan="3" screen="xs sm md lg" />
        <template #default >
          <basic-chart-visualizer :component-title="loc.OCCUPANCY.UNITS" :comparisonDate="comparisonDateOccupancy" :refDate="refDateOccupancy" :legendItemColor1="outletLofStyle.primaryColor" :legendItemColor2="outletLofStyle.secondaryColor">
            <template v-slot:componentDoughnut>
              <DxChart class="stackedbar" @drawn="onDrawn" id="chart" :data-source="fullstackedbar.dataSource">

                <DxArgumentAxis position="bottom" />

                <DxCommonSeriesSettings

                    :label="{
                  font: {
                    color: '#000000'
                  },
                }"

                    argument-field="units" type="stackedbar" :barWidth="35" vertical-alignment="top" />


                <DxSeries value-field="leasedUnits" :color="outletLofStyle.primaryColor" :name="comparisonDateText" stack="units">
                  <DxLabel :visible="true" background-color="rgba(255,255,255,0)"/>
                </DxSeries>

                <DxSeries value-field="vacantUnits" :color="outletLofStyle.secondaryColor" :name="refDateText" stack="units">
                  <DxLabel :visible="true" background-color="rgba(255,255,255,0)"/>
                </DxSeries>

                <DxSeries value-field="total" :color="colorTrasparent" name="" :label="{visible:true, horizontalOffset: 35, verticalOffset: 30}" type="scatter"/>

                <DxLegend :visible="false"/>

                <DxExport :enabled="false"/>

                <DxTooltip :enabled="true"/>

              </DxChart>
            </template>
          </basic-chart-visualizer>
        </template>
      </DxItem>

      <!-- ############## 3rd row ############## -->
      <!--   FOOTFALL   -->
<!--      <DxItem class="item text" v-if="!portfolioVisualization">-->
<!--        <DxLocation :row="3" :col="0" :colspan="3" screen="xs sm md lg" />-->
<!--        <template #default >-->
<!--          <basic-text-visualizer-->
<!--              :component-title="loc.MAINPANEL.VISITORSTEXTTITLE"-->
<!--              :comparison-date="comparisonDateYTD"-->
<!--              :comparison-value="textVisitors.dataFilteredByProductCategory.visitorsPY"-->
<!--              :ref-date="refDateYTD"-->
<!--              :ref-value="textVisitors.dataFilteredByProductCategory.visitors"-->
<!--              :trend-value="textVisitors.dataFilteredByProductCategory.varVisitors"-->
<!--          />-->
<!--        </template>-->
<!--      </DxItem>-->

<!--      <DxItem class="item text" v-if="!portfolioVisualization">-->
<!--        <DxLocation :row="3" :col="3" :colspan="3" screen="xs sm md lg" />-->
<!--        <template #default >-->
<!--          <basic-text-visualizer-->
<!--              :component-title="loc.MAINPANEL.CRTEXTTITLE"-->
<!--              :comparison-date="comparisonDateYTD"-->
<!--              :comparison-value="textVisitors.dataFilteredByProductCategory.crPY"-->
<!--              :ref-date="refDateYTD"-->
<!--              :ref-value="textVisitors.dataFilteredByProductCategory.cr"-->
<!--              :trend-value="textVisitors.dataFilteredByProductCategory.varCR"-->
<!--              :is-percentage="true"-->
<!--          />-->
<!--        </template>-->
<!--      </DxItem>-->

      <!--Net Sales-->
      <!-- 1st row -->
      <!-- 1st column -->
<!--      <DxItem v-if="!portfolioVisualization">-->
<!--        <DxLocation :row="4" :col="0" :colspan="2" screen="lg" />-->
<!--        <DxLocation :row="4" :col="0" :colspan="3" screen="xs sm md" />-->
<!--        <template #default>-->
<!--          <basic-text-visualizer-->
<!--              :componentTitle="loc.NETSALES.NETSALES"-->
<!--              :comparisonDate="comparisonDateText"-->
<!--              :comparisonValue="Math.round(cloumnStatistic.netSales.salesREFPY)"-->
<!--              :refDate="refDateText"-->
<!--              :refValue="Math.round(cloumnStatistic.netSales.salesREF)"-->
<!--              :trendValue="cloumnStatistic.netSales.varSales"-->
<!--              :isCurrency="true"-->
<!--          />-->
<!--        </template>-->
<!--      </DxItem>-->
<!--      &lt;!&ndash; 2st column &ndash;&gt;-->
<!--      <DxItem v-if="!portfolioVisualization">-->
<!--        <DxLocation :row="4" :col="2" :colspan="2" screen="lg" />-->
<!--        <DxLocation :row="4" :col="3" :colspan="3" screen="xs sm md" />-->
<!--        <template #default>-->
<!--          <basic-text-visualizer-->
<!--              :componentTitle="loc.NETSALES.NETSALESSQM"-->
<!--              :comparisonDate="comparisonDateText"-->
<!--              :comparisonValue="cloumnStatistic.netSalesSqm.salesSqmREFPY"-->
<!--              :refDate="refDateText"-->
<!--              :refValue="cloumnStatistic.netSalesSqm.salesSqmREF"-->
<!--              :trendValue="cloumnStatistic.netSalesSqm.varSalesSqm"-->
<!--              :isCurrency="true"-->
<!--          />-->
<!--        </template>-->
<!--      </DxItem>-->
<!--      &lt;!&ndash; 3st column &ndash;&gt;-->
<!--      <DxItem v-if="!portfolioVisualization">-->
<!--        <DxLocation :row="4" :col="4" :colspan="2" screen="lg" />-->
<!--        <DxLocation :row="5" :col="0" :colspan="3" screen="xs sm md" />-->
<!--        <template #default>-->
<!--          <basic-text-visualizer-->
<!--              :componentTitle="loc.NETSALES.AVGTICKET"-->
<!--              :comparisonDate="comparisonDateText"-->
<!--              :comparisonValue="cloumnStatistic.avegTicket.avgTicketREFPY"-->
<!--              :refDate="refDateText"-->
<!--              :refValue="cloumnStatistic.avegTicket.avgTicketREF"-->
<!--              :trendValue="cloumnStatistic.avegTicket.varAvgTicket"-->
<!--              :isCurrency="true"-->
<!--          />-->
<!--        </template>-->
<!--      </DxItem>-->

<!--      &lt;!&ndash; 2st row &ndash;&gt;-->
<!--      &lt;!&ndash; 1st column &ndash;&gt;-->
<!--      <DxItem v-if="!portfolioVisualization">-->
<!--        <DxLocation :row="5" :col="0" :colspan="2" screen="lg" />-->
<!--        <DxLocation :row="5" :col="3" :colspan="3" screen="xs sm md" />-->
<!--        <template #default>-->
<!--          <basic-text-visualizer-->
<!--              :componentTitle="loc.NETSALES.SPENDPERVISITOR"-->
<!--              :comparisonDate="comparisonDateText"-->
<!--              :comparisonValue="cloumnStatistic.spendPerVisitor.spvREFPY"-->
<!--              :refDate="refDateText"-->
<!--              :refValue="cloumnStatistic.spendPerVisitor.spvREF"-->
<!--              :trendValue="cloumnStatistic.spendPerVisitor.varSPV"-->
<!--              :isCurrency="true"-->
<!--          />-->
<!--        </template>-->
<!--      </DxItem>-->
<!--      &lt;!&ndash; 2st column &ndash;&gt;-->
<!--      <DxItem v-if="!portfolioVisualization">-->
<!--        <DxLocation :row="5" :col="2" :colspan="2" screen="lg" />-->
<!--        <DxLocation :row="6" :col="0" :colspan="3" screen="xs sm md" />-->
<!--        <template #default>-->
<!--          <basic-text-visualizer-->
<!--              :componentTitle="loc.NETSALES.OPENDAYS"-->
<!--              :comparisonDate="comparisonDateText"-->
<!--              :comparisonValue="cloumnStatistic.openDays.openDaysREFPY"-->
<!--              :refDate="refDateText"-->
<!--              :refValue="cloumnStatistic.openDays.openDaysREF"-->
<!--              :emptyTrendValue="true"-->
<!--          />-->
<!--        </template>-->
<!--      </DxItem>-->
<!--      &lt;!&ndash; 3st column &ndash;&gt;-->
<!--      <DxItem v-if="!portfolioVisualization">-->
<!--        <DxLocation :row="5" :col="4" :colspan="2" screen="lg" />-->
<!--        <DxLocation :row="6" :col="3" :colspan="3" screen="xs sm md" />-->
<!--        <template #default>-->
<!--          <basic-text-visualizer-->
<!--              :componentTitle="loc.NETSALES.CRRETAIL"-->
<!--              :comparisonDate="comparisonDateText"-->
<!--              :comparisonValue="cloumnStatistic.crRetail.crREFPY"-->
<!--              :refDate="refDateText"-->
<!--              :refValue="cloumnStatistic.crRetail.crREF"-->
<!--              :trendValue="cloumnStatistic.crRetail.varCR"-->
<!--              :isPercentage="true"-->
<!--          />-->
<!--        </template>-->
<!--      </DxItem>-->

      <!-- ############## 6th row ############## -->
      <!--   TENANTS   -->
<!--      <DxItem v-if="!portfolioVisualization">-->
<!--        <DxLocation :row="6" :col="0" :colspan="6" screen="lg" />-->
<!--        <DxLocation :row="7" :col="0" :colspan="6" screen="xs sm md" />-->
<!--        <template #default>-->
<!--          <div style="position: relative;">-->

<!--            <DxButton-->
<!--                :style="`display: ${dataDisplayMode == 'chart' ? 'block' : 'none'}`"-->
<!--                id="reset-zoom"-->
<!--                class="reset-zoom-btn"-->
<!--                :on-click="resetScatterErZoom"-->
<!--                :text="loc.COMMON.RESETZOOMTEXT"-->
<!--            />-->

<!--            <advanced-datagrid-visualizer-->
<!--                :componentTitle="loc.MAINPANEL.TENANTSTITLE"-->
<!--                :refDate="refDateText"-->
<!--                dataReady=""-->
<!--                :notes="[(loc.MAINPANEL.TENANTSNOTE2 + refDateText), (loc.MAINPANEL.TENANTSNOTE1PART1 + tenantsDatagridData.tboBreakDate + loc.MAINPANEL.TENANTSNOTE1PART2)]"-->
<!--                :switch-table-btn="loc.EFFORTRATE.CHARTDATAGRIDSWITCHTABLE"-->
<!--                :switch-chart-btn="loc.EFFORTRATE.CHARTDATAGRIDSWITCHCHART"-->
<!--                :switchItems="[loc.MAINPANEL.TENANTSDATAGRIDTAB0, loc.MAINPANEL.TENANTSDATAGRIDTAB1, loc.MAINPANEL.TENANTSDATAGRIDTAB2, loc.MAINPANEL.TENANTSDATAGRIDTAB3]"-->
<!--                @changeDisplayDataChild="changeDisplayData($event)"-->
<!--            >-->

<!--              &lt;!&ndash; ## SCATTER PLOT &ndash;&gt;-->
<!--              <template v-slot:componentChart>-->

<!--              </template>-->

<!--              <template v-slot:componentTab0>-->

<!--                &lt;!&ndash; ## DATAGRID &ndash;&gt;-->
<!--                <DxDataGrid-->
<!--                    id="dataGrid-filtered-by-client"-->
<!--                    class="datagrid"-->
<!--                    :data-source="tenantsDatagridData.openingTenants"-->
<!--                    key-expr="tenantId"-->
<!--                    :show-borders="false"-->
<!--                    :loadPanel="{enabled: true}"-->
<!--                    @exporting="onExportingDatagridEr"-->
<!--                    :show-column-lines="false"-->
<!--                    :show-row-lines="true"-->
<!--                    :column-auto-width="true"-->
<!--                    :column-hiding-enabled="true"-->
<!--                    :noDataText="loc.COMMON.NODATA"-->
<!--                >-->
<!--                  <DxPaging :enabled="false" />-->
<!--                  <DxExport-->
<!--                      :enabled="true"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      sort-order="asc"-->
<!--                      data-field="tenant"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB0COL0"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style cell-body-head"-->
<!--                      cell-template="diff-cell-template"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      :allow-sorting="false"-->
<!--                      data-field="tenantId"-->
<!--                      caption=""-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      cell-template="link-cell-template"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="company"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB0COL1"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="openDate"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB0COL2"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      :customizeText="customCellDateText"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="unit"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB0COL3"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->

<!--                  <DxColumn-->
<!--                      css-class="cell-custom-style"-->
<!--                      data-field="gla"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB0COL4"-->
<!--                      data-type="number"-->
<!--                      alignment="left"-->
<!--                      :customizeText="customizeDatagridDataDecimals"-->
<!--                  >-->
<!--                    <DxFormat-->
<!--                        type="fixedPoint"-->
<!--                        :precision="2"-->
<!--                    />-->
<!--                  </DxColumn>-->

<!--                  <DxColumn-->
<!--                      data-field="prodCat"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB0COL5"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->
<!--                  &lt;!&ndash;                  <DxColumn&ndash;&gt;-->
<!--                  &lt;!&ndash;                      css-class="cell-custom-style"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      data-field="er"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      data-type="number"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      alignment="left"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      :customizeText="customizeDatagridDataPercentage"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      sort-order="asc"&ndash;&gt;-->
<!--                  &lt;!&ndash;                  >&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <DxFormat&ndash;&gt;-->
<!--                  &lt;!&ndash;                        type="fixedPoint"&ndash;&gt;-->
<!--                  &lt;!&ndash;                        :precision="2"&ndash;&gt;-->
<!--                  &lt;!&ndash;                    />&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </DxColumn>&ndash;&gt;-->

<!--                  &lt;!&ndash; template personalizzato celle datagrid &ndash;&gt;-->
<!--                  <template #diff-cell-template="{ data }">-->
<!--                    <DiffCell :cell-data="data"/>-->
<!--                  </template>-->

<!--                  <template #link-cell-template="{ data }">-->
<!--                    <LinkCellTemplate :data="data" />-->
<!--                  </template>-->
<!--                </DxDataGrid>-->
<!--              </template>-->

<!--              <template v-slot:componentTab1>-->

<!--                &lt;!&ndash; ## DATAGRID &ndash;&gt;-->
<!--                <DxDataGrid-->
<!--                    id="dataGrid-filtered-by-client"-->
<!--                    class="datagrid"-->
<!--                    :data-source="tenantsDatagridData.closingTenants"-->
<!--                    key-expr="tenantId"-->
<!--                    :show-borders="false"-->
<!--                    :loadPanel="{enabled: true}"-->
<!--                    @exporting="onExportingDatagridEr"-->
<!--                    :show-column-lines="false"-->
<!--                    :show-row-lines="true"-->
<!--                    :column-auto-width="true"-->
<!--                    :column-hiding-enabled="true"-->
<!--                    :noDataText="loc.COMMON.NODATA"-->
<!--                >-->
<!--                  <DxPaging :enabled="false" />-->
<!--                  <DxExport-->
<!--                      :enabled="true"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      sort-order="asc"-->
<!--                      data-field="tenant"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB1COL0"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style cell-body-head"-->
<!--                      cell-template="diff-cell-template"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      :allow-sorting="false"-->
<!--                      data-field="tenantId"-->
<!--                      caption=""-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      cell-template="link-cell-template"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="company"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB1COL1"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->
<!--                  &lt;!&ndash;                  closedate&ndash;&gt;-->
<!--                  <DxColumn-->
<!--                      data-field="closeDate"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB1COL2"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      :customizeText="customCellDateText"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="unit"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB1COL3"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->

<!--                  <DxColumn-->
<!--                      css-class="cell-custom-style"-->
<!--                      data-field="gla"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB1COL4"-->
<!--                      data-type="number"-->
<!--                      alignment="left"-->
<!--                      :customizeText="customizeDatagridDataDecimals"-->
<!--                  >-->
<!--                    <DxFormat-->
<!--                        type="fixedPoint"-->
<!--                        :precision="2"-->
<!--                    />-->
<!--                  </DxColumn>-->

<!--                  <DxColumn-->
<!--                      data-field="prodCat"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB1COL5"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->
<!--                  &lt;!&ndash;                  <DxColumn&ndash;&gt;-->
<!--                  &lt;!&ndash;                      css-class="cell-custom-style"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      data-field="er"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      data-type="number"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      alignment="left"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      :customizeText="customizeDatagridDataPercentage"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      sort-order="asc"&ndash;&gt;-->
<!--                  &lt;!&ndash;                  >&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <DxFormat&ndash;&gt;-->
<!--                  &lt;!&ndash;                        type="fixedPoint"&ndash;&gt;-->
<!--                  &lt;!&ndash;                        :precision="2"&ndash;&gt;-->
<!--                  &lt;!&ndash;                    />&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </DxColumn>&ndash;&gt;-->

<!--                  &lt;!&ndash; template personalizzato celle datagrid &ndash;&gt;-->
<!--                  <template #diff-cell-template="{ data }">-->
<!--                    <DiffCell :cell-data="data"/>-->
<!--                  </template>-->

<!--                  <template #link-cell-template="{ data }">-->
<!--                    <LinkCellTemplate :data="data" />-->
<!--                  </template>-->
<!--                </DxDataGrid>-->
<!--              </template>-->

<!--              <template v-slot:componentTab2>-->

<!--                &lt;!&ndash; ## DATAGRID &ndash;&gt;-->
<!--                <DxDataGrid-->
<!--                    id="dataGrid-filtered-by-client"-->
<!--                    class="datagrid"-->
<!--                    :data-source="tenantsDatagridData.indemnitySituations"-->
<!--                    key-expr="tenantId"-->
<!--                    :show-borders="false"-->
<!--                    :loadPanel="{enabled: true}"-->
<!--                    @exporting="onExportingDatagridEr"-->
<!--                    :show-column-lines="false"-->
<!--                    :show-row-lines="true"-->
<!--                    :column-auto-width="true"-->
<!--                    :column-hiding-enabled="true"-->
<!--                    :noDataText="loc.COMMON.NODATA"-->
<!--                >-->
<!--                  <DxPaging :enabled="false" />-->
<!--                  <DxExport-->
<!--                      :enabled="true"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      sort-order="asc"-->
<!--                      data-field="tenant"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB2COL0"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style cell-body-head"-->
<!--                      cell-template="diff-cell-template"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      :allow-sorting="false"-->
<!--                      data-field="tenantId"-->
<!--                      caption=""-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      cell-template="link-cell-template"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="company"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB2COL1"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="contractExpiryDate"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB2COL2"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      :customizeText="customCellDateText"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="unit"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB2COL3"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->

<!--                  <DxColumn-->
<!--                      css-class="cell-custom-style"-->
<!--                      data-field="gla"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB2COL4"-->
<!--                      data-type="number"-->
<!--                      alignment="left"-->
<!--                      :customizeText="customizeDatagridDataDecimals"-->
<!--                  >-->
<!--                    <DxFormat-->
<!--                        type="fixedPoint"-->
<!--                        :precision="2"-->
<!--                    />-->
<!--                  </DxColumn>-->

<!--                  <DxColumn-->
<!--                      data-field="prodCat"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB2COL5"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->
<!--                  &lt;!&ndash;                  <DxColumn&ndash;&gt;-->
<!--                  &lt;!&ndash;                      css-class="cell-custom-style"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      data-field="er"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      data-type="number"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      alignment="left"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      :customizeText="customizeDatagridDataPercentage"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      sort-order="asc"&ndash;&gt;-->
<!--                  &lt;!&ndash;                  >&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <DxFormat&ndash;&gt;-->
<!--                  &lt;!&ndash;                        type="fixedPoint"&ndash;&gt;-->
<!--                  &lt;!&ndash;                        :precision="2"&ndash;&gt;-->
<!--                  &lt;!&ndash;                    />&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </DxColumn>&ndash;&gt;-->

<!--                  &lt;!&ndash; template personalizzato celle datagrid &ndash;&gt;-->
<!--                  <template #diff-cell-template="{ data }">-->
<!--                    <DiffCell :cell-data="data"/>-->
<!--                  </template>-->

<!--                  <template #link-cell-template="{ data }">-->
<!--                    <LinkCellTemplate :data="data" />-->
<!--                  </template>-->
<!--                </DxDataGrid>-->
<!--              </template>-->

<!--              <template v-slot:componentTab3>-->

<!--                &lt;!&ndash; ## DATAGRID &ndash;&gt;-->
<!--                <DxDataGrid-->
<!--                    id="dataGrid-filtered-by-client"-->
<!--                    class="datagrid"-->
<!--                    :data-source="tenantsDatagridData.tboImmediateSituations"-->
<!--                    key-expr="tenantId"-->
<!--                    :show-borders="false"-->
<!--                    :loadPanel="{enabled: true}"-->
<!--                    @exporting="onExportingDatagridEr"-->
<!--                    :show-column-lines="false"-->
<!--                    :show-row-lines="true"-->
<!--                    :column-auto-width="true"-->
<!--                    :column-hiding-enabled="true"-->
<!--                    :noDataText="loc.COMMON.NODATA"-->
<!--                >-->
<!--                  <DxPaging :enabled="false" />-->
<!--                  <DxExport-->
<!--                      :enabled="true"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      sort-order="asc"-->
<!--                      data-field="tenant"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB3COL0"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style cell-body-head"-->
<!--                      cell-template="diff-cell-template"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      :allow-sorting="false"-->
<!--                      data-field="tenantId"-->
<!--                      caption=""-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      cell-template="link-cell-template"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="company"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB3COL1"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="contractExpiryDate"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB3COL2"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      :customizeText="customCellDateText"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="tboExpiryDate"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB3COL3"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      :customizeText="customCellDateText"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="tboLatestNoticeDate"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB3COL4"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      :customizeText="customCellDateText"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="tboRolling"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB3COL5"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                      cell-template="rolling-cell-template"-->
<!--                  />-->
<!--                  <DxColumn-->
<!--                      data-field="unit"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB3COL6"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->

<!--                  <DxColumn-->
<!--                      css-class="cell-custom-style"-->
<!--                      data-field="gla"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB3COL7"-->
<!--                      data-type="number"-->
<!--                      alignment="left"-->
<!--                      :customizeText="customizeDatagridDataDecimals"-->
<!--                  >-->
<!--                    <DxFormat-->
<!--                        type="fixedPoint"-->
<!--                        :precision="2"-->
<!--                    />-->
<!--                  </DxColumn>-->

<!--                  <DxColumn-->
<!--                      data-field="prodCat"-->
<!--                      :caption="loc.MAINPANEL.TENANTSTAB3COL8"-->
<!--                      data-type="string"-->
<!--                      alignment="left"-->
<!--                      css-class="cell-custom-style"-->
<!--                  />-->
<!--                  &lt;!&ndash;                  <DxColumn&ndash;&gt;-->
<!--                  &lt;!&ndash;                      css-class="cell-custom-style"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      data-field="er"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      data-type="number"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      alignment="left"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      :customizeText="customizeDatagridDataPercentage"&ndash;&gt;-->
<!--                  &lt;!&ndash;                      sort-order="asc"&ndash;&gt;-->
<!--                  &lt;!&ndash;                  >&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <DxFormat&ndash;&gt;-->
<!--                  &lt;!&ndash;                        type="fixedPoint"&ndash;&gt;-->
<!--                  &lt;!&ndash;                        :precision="2"&ndash;&gt;-->
<!--                  &lt;!&ndash;                    />&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </DxColumn>&ndash;&gt;-->

<!--                  &lt;!&ndash; template personalizzato celle datagrid &ndash;&gt;-->
<!--                  <template #diff-cell-template="{ data }">-->
<!--                    <DiffCell :cell-data="data"/>-->
<!--                  </template>-->

<!--                  <template #link-cell-template="{ data }">-->
<!--                    <LinkCellTemplate :data="data" />-->
<!--                  </template>-->

<!--                  <template #rolling-cell-template="{ data }">-->
<!--                    <RollingCellTemplate :data="data" />-->
<!--                  </template>-->
<!--                </DxDataGrid>-->
<!--                &lt;!&ndash;                <div style="color: #666666; padding-top: 5px">(*) Break date by the end of {{ tenantsDatagridData.tboBreakDate }} - TBO Rolling included</div>&ndash;&gt;-->
<!--              </template>-->



<!--            </advanced-datagrid-visualizer>-->
<!--          </div>-->

<!--        </template>-->
<!--      </DxItem>-->


      <!--   footer   -->
      <!--      <DxItem>-->
      <!--        <DxLocation :row="6" :col="3" :colspan="3" screen="lg" />-->
      <!--        <DxLocation :row="7" :col="3" :colspan="3" screen="xs sm md" />-->
      <!--        <template #default>-->
      <!--          <div class="app-version">v. {{ getStoredAppVersion.versionNumber }} - {{ getStoredAppVersion.versionDate }}</div>-->
      <!--        </template>-->
      <!--      </DxItem>-->

    </DxResponsiveBox>

  </div>
</template>

<script>
import auth from"@/auth";
import itMessages from 'devextreme/localization/messages/it.json';
import { locale, loadMessages } from "devextreme/localization";

import { loc } from "@/config/languages/en.js";

import DxSelectBox from 'devextreme-vue/select-box';
import 'devextreme/dist/css/dx.light.css';
import ApiCalls from "@/services/ApiCalls";

import { mapGetters } from "vuex";
import DxCalendar from 'devextreme-vue/calendar';

import {
  DxResponsiveBox,
  DxItem,
  DxLocation,
  DxCol,
  DxRow,
} from "devextreme-vue/responsive-box";

/******************************************************************************************************
 * # COMPONENTE - INIZIO
 **/

import { DxBarGauge } from 'devextreme-vue/bar-gauge';

import DxDataGrid, {
  // DxLoadPanel,
  DxPaging, DxColumn, DxFormat, DxExport } from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import {
  DxChart,
  DxSeries,
  // DxPoint,
  DxCommonSeriesSettings,
  DxLegend,
  // DxValueAxis,
  DxArgumentAxis,
  // DxGrid,
  // DxMinorGrid,
  // DxCommonPaneSettings,
  // DxBorder,
  DxTooltip,
  //
  // DxZoomAndPan,

  // DxSize,
} from "devextreme-vue/chart";

import {
  // DxFormat,
  DxLabel,
  // DxConnector,
  // DxExport
} from 'devextreme-vue/pie-chart';

// import DxButton from 'devextreme-vue/button';


import DiffCell from '@/components/datagrid/DiffCell.vue';

/**
 * # /COMPONENTE - FINE
 ******************************************************************************************************/


export default {
  components: {
    DxResponsiveBox,
    DxItem,
    DxLocation,
    DxCol,
    DxRow,
    DxSelectBox,
    // DxButton,
    DxCalendar,

    /******************************************************************************************************
     * # COMPONENTE - INIZIO
     **/
    // DxDataGrid,
    // DxLoadPanel,
    // DxPaging,
    DxExport,


    DxChart,
    DxSeries,
    // DxPoint,
    DxCommonSeriesSettings,
    DxLegend,
    // DxValueAxis,
    DxArgumentAxis,
    // DxGrid,
    // DxMinorGrid,
    // DxCommonPaneSettings,
    // DxBorder,
    DxTooltip,
    // DxZoomAndPan,
    // // DxSize,
    // DxColumn,
    // DxFormat,


    // DxPieChart,
    // DxFormat,
    DxLabel,
    // DxConnector,
    //DxExport,

    DxBarGauge,

    // DiffCell, // componente per template personalizzato celle datagrid

    /**
     * # /COMPONENTE - FINE
     ******************************************************************************************************/
  },

  data() {
    /***************
     * # PROVVISORIO: ARRIVERà DA VUEX
     ***************/
        // let outlet = this.$store.getters.getSelectedOutlet.id ? this.$store.getters.getSelectedOutlet.id : 1; // #1
        // let refDate = {year: 2021, month: 10};
    let selectedProductCategory = 0;
    // console.log("er outlet from store: ", outlet)

    return {
      /***************
       * # PROVVISORIO: ARRIVERà DA VUEX
       ***************/
      // parametri per composizione chiamate - prov (arriveranno da vuex, impostato da subheader/partials/drop-down component)
      // outletId: outlet, /* #globale */
      // refDate: refDate, /* #globale */

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      /** ## STILE PER I COMPONENTI LOF **********/
      outletLofStyle: {},

      /** ## DATE PER I COMPONENTI **********/
      refDateLTM: "",
      comparisonDateLTM: "",

      refDateYTD: "",
      comparisonDateYTD: "",

      refDateText: "", // arriva da chiamata refDate.plain
      comparisonDateText: "", // arriva da chiamata comparisonDate.plain
      refDateTextGauge: "",
      comparisonDateTextGauge: "",

      comparisonDateOccupancy: "", // necessario perché altrimenti il componente non riceve i dati in tempo e non compare al primo caricamento
      refDateOccupancy: "", // necessario perché altrimenti il componente non riceve i dati in tempo e non compare al primo caricamento

      selectedProductCategory: selectedProductCategory, // #globale

      /** ## SELETTORE CATEGORIE MERCEOLOGICHE **********/
      categories: [], // todo: globale o componente? ogni quanto si ripopolerà? verrà preso da localstorage dopo prima chiamata?

      // DATAGRID CATEGORIES COMPONENT
      // selectedCategory: null, // forse non più utile... #check
      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/

      /***************
       * # GENERALI E GLOBALI
       ***************/
      effortRateData: null, /************* # COMPONENTE *********************************/
      // dataFilteredByProductCategory: [], // #componente

      // flag per abilitare eventuali pulsanti solo quando i dati sono stati ricevuti
      pageDataReady: false, /************* # COMPONENTE *********************************/

      // flag per abilitare filtro categorie merceologiche (dipende da pagina - domain) #n
      productCategoryFilterDisabledByPage: null,

      /** ## LOCALIZZAZIONE **********/
      locale: "it",

      loc: loc, // loc

      /** ## BREAKPOINTS **********/
      screenByWidth: function (width) {
        if (width < 768) return "xs";
        if (width < 1024) return "sm";
        if (width < 1150) return "md";
        // if (width < 1150) return "md";
        return "lg";
      },

      //Settings
      settingsZoomLevel: "decade",
      settingsMaxSelectableDate: null,
      settingsMinSelectableDate: null,
      settingsHidden: true,

      /** ## CALENDAR **********/
      calendarZoomLevel: "year", /* #componente: impostata di default, dipende da pagina: usata per cambiare stato active */
      maxSelectableDate: null,
      minSelectableDate: null, // #n
      calendarHidden: true,

      /** ## BAR GAUGE **********/
      barGaugesLabelsSettings: {indent: 10, format: this.format, customizeText: this.customizeText, font: this.barGaugeLabelFont},
      barGaugesOccupancyLabelsSettings: {indent: 10, format: { type: 'fixedPoint', precision: 2 }, customizeText: this.customizeText, font: this.barGaugeLabelFont},

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      // datagrid expiry custom date cell
      customCellDateText(value) {
        let rowDate = value.valueText;
        return rowDate == '' ? '' : `${rowDate.slice(6,8)}/${rowDate.slice(4,6)}/${rowDate.slice(0,4)}`; // #qui: da sostituire con funzione globale displayCorrectFormat
      },

      /** ## BAR GAUGE **********/
      format: { /* #globale */
        type: 'fixedPoint',
        precision: 1
      },

      formatOccupancy: { /* #globale */
        type: 'fixedPoint',
        precision: 2
      },

      /** ## DATAGRID **********/
      customizeDatagridDataDecimals(value) { /* #globale */
        // console.log("custom data col: ", value)
        return value.valueText;
        // return value.valueText + " €";
      },

      customizeDatagridDataPercentage(value) { /* #globale */
        return value.valueText;
        // return value.valueText + " %";
      },

      // datagrid loader chiamata filtrata via api: ancora utile?
      loadingVisible: true, /* #componente */

      /***************
       * # COMPONENT ER LTM DISTRIBUTION - BAR GAUGE
       ***************/
      // todo: trasformare in oggetto ogni componente (solo var componente)?

      cloumnStatistic: {
        dataFilteredByProductCategory: [],
        dataFilteredByProductCategoryLTM: [],
        dataFilteredByProductCategoryYTD: [],
        netSales: {
          salesREFPY: 0,
          salesREF: 0,
          varSales: 0
        },
        netSalesSqm: {
          salesSqmREFPY: 0,
          salesSqmREF: 0,
          varSalesSqm: 0
        },
        avegTicket: {
          avgTicketREFPY: 0,
          avgTicketREF: 0,
          varAvgTicket: 0
        },
        spendPerVisitor: {
          spvREFPY: 0,
          spvREF: 0,
          varSPV: 0
        },
        openDays: {
          openDaysREFPY: 0,
          openDaysREF: 0
        },
        crRetail: {
          crREFPY: 0,
          crREF: 0,
          varCR: 0
        }
      },

      barGaugeER: { // #componente
        dataRef: [],
        dataComparison: [],
        legend: {},
      },

      barGaugeEROccupancy: { // #componente
        dataRef: [],
        dataComparison: [],
        legend: {},
      },

      fullstackedbar: {
        dataSource: [],
        leasedUnitsREF: 0,
        vacantUnitsREF: 0,
        totaleREF: 0,
        leasedUnitsREFPY: 0,
        vacantUnitsREFPY: 0,
        totaleREFPY: 0,
      },

      barGaugeGeometry: { /* #globale */
        endAngle:-30,
        startAngle:210
      },
      colorGood: "#59C47F", /* #globale */
      colorNormal: "#FFD341", /* #globale */
      colorBad: "#FF5633", /* #globale */
      colorBadLight: "#F8B5BF", /* #globale */
      colorTrasparent: "rgba(255,255,255,0)", /* #globale */
      barGaugeLabelFont: {family: 'Nunito, sans-serif', size: 18, weight: 400}, /* #globale */
      // todo: label font-size a 15 in 768 e 1024

      /** ## TENANTS **********/
      tenantsDatagridData: null,


      /***************
       * # COMPONENT TEXT VISITORS
       ***************/
      textVisitors: {
        dataFilteredByProductCategory: {},
        dataFilteredByProductCategoryLTM: {},
        dataFilteredByProductCategoryREF: {},
      },


      /***************
       * # COMPONENT ER LTM
       ***************/
      scatterDatagridERLTM: {
        // componentTitle: "Effort Rate LTM %",
        dataFilteredByProductCategory: null,
      },

      /** ## SCATTER PLOT **********/
      effortRateDisplayMode: "chart", /* #componente */

      scatterErInstance1: null, /* #componente */
      scatterErInstance2: null, /* #componente */

      scatterChartCommonAxisSettings: { /* #globale */
        //todo: color non viene applicato: scoprire cosa lo sovrascrive
        label:
            { font:
                  { size: 14, family: 'Nunito', weight: 400, color: '#787878' }
            },
        title:
            { margin: 14 ,
              font:
                  { size: 14, family: 'Nunito', weight: 400, color: '#666666' }
            }
      },

      scatterERLTM: {
        // argumentAxis: {
        //   title: { text: 'Net Sales (€)'},
        // },
        // valueAxis: {
        //   title: { text: 'E.R. (%)'},
        // },
        series1: {
          color: "#FF5633",
        },
        series2: {
          color: "#F8B5BF",
        },
        points1: {
          color: "#FF7373",
          size: 15,
        },
        points2: {
          color: "#F8B5BF",
          size: 15,
        },
        tooltipVisitors: {
          argumentFormat: { type: 'fixedPoint' },
          valueFormat: { type: 'fixedPoint' },
        },
        tooltipCr: {
          argumentFormat: { type: 'fixedPoint' },
          valueFormat: { type: 'decimal', precision: 2 },
        },
      },

      /***************
       * # COMPONENT WORST PERFORMER - DATAGRID
       ***************/
      datagridERWorstPerformer: {
        // componentTitle: "Worst Performers",
        dataFilteredByProductCategory: null,
      },

      /***************
       * # COMPONENT BEST PERFORMER - DATAGRID
       ***************/
      datagridERBestPerformer: {
        // componentTitle: "Best Performers",
        dataFilteredByProductCategory: null,
      },

      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/

    };
  },

  computed: {
    ...mapGetters(["getSelectedOutlet", "getOutletsList", "getCategoriesList", "getOutletLastSalesDates", "getNewOutletLastSalesDates", "getOutletStyle", "getStoredAppVersion", "getLegendFontSettings"]), // #n


    /***************
     * # CALENDAR
     ***************/
    displayStringDate() { /* #globale */
      if(this.$store.state.selectedDate) {
        let year = this.$store.state.selectedDate.slice(0, 4);
        let month = this.fromOrdinalToMonth(Number((this.$store.state.selectedDate.slice(3, 5) - 1)))

        return `${month} ${year}`;
      } else { // #n
        let year = this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(0, 4);
        let month = this.fromOrdinalToMonth(Number((this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(3, 5) - 1)))
        // /#n

        return `${month} ${year}`;
      }
    },
  },

  methods: {
    prova() {
      console.log("prova", this.$store.state.selectedDate)
      //console.log(`prova: ${String(this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4)}-${String(this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(4,6)}-01`)
    },

    /***************
     * # CALENDAR
     ***************/

    // funzione per convertire numeri da una a due cifre
    convertFormatDate(date) {
      console.log("date: ", date);
      return String(date).length < 2 ? ("0" + date) : date;
    },

    onSelectedDate(e) {
      // console.log("selectedDate: ", new Date(e.value));
      let temp = new Date(e.value);
      // console.log("temp: ", temp);
      let selectedDate = `${temp.getFullYear()}${this.convertFormatDate(Number(temp.getMonth())+1)}${this.convertFormatDate(temp.getDate())}`
      // console.log("selectedDate: ", selectedDate);

      // imposto data selezionata in vuex
      this.$store.dispatch("dispatchSelectedDate", selectedDate);
      console.log("selectedDate - vuex: ", this.$store.state.selectedDate);

      // chiamata api per riprendere dati pagina er (tenendo conto di categoria selezionata)
      this.getEffortRateData();

      // nascondi il calendario
      this.calendarHidden = true;

    },

    onSelectedSetting(e) {

      let temp = new Date(e.value);
      let selectedSetting = `${temp.getFullYear()}`;
      this.$store.dispatch("dispatchSelectedSetting", selectedSetting);

      this.getEffortRateData();
      this.settingsHidden = true;
    },

    // funzione per impostare il range di date selezionabili (month, week)
    onCalendarZoomLevelSelection(calendarZoomLevelSelected) {
      // impostazione zoom level calendario
      this.calendarZoomLevel = calendarZoomLevelSelected.value;
      console.log("calendar zoom level: ", this.calendarZoomLevel);

      // imposta ultima data selezionabile
      this.setMaxSelectableDate()
    },

    // imposta ultima data selezionabile
    setMaxSelectableDate() { //#n
      if(this.calendarZoomLevel === "year") {
        // console.log("prova start date: ", this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate);
        let tempLastDateYear = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(0, 4);
        let tempLastDateMonth = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(4, 6);

        // ricavo data di partenza in formato data
        let tempLastDate = new Date(tempLastDateYear, tempLastDateMonth, 0);

        // ricreo la data in formato devextreme e la imposto nel calendario
        this.maxSelectableDate = `${tempLastDate.getFullYear()}${(tempLastDate.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempLastDate.getDate()}`; // #n

        // console.log("calendar max selectable date: ", this.maxSelectableDate);
        // console.log("calendar max selectable date - old: ", this.$store.state.outletLastSalesDates.salesLastDayClosedMonth);  // old

        //  imposto min data selezionabile su calendario // #n
        console.log("prova start first date: ", this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate);
        let tempFirstDateYear = (this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate + "").slice(0, 4);
        let tempFirstDateMonth = (this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate + "").slice(4, 6);

        // ricavo data di partenza in formato data
        let tempFirstDate = new Date(tempFirstDateYear, tempFirstDateMonth, 1);
        console.log("temp first date: ", tempFirstDate);

        // ricreo la data in formato devextreme e la imposto nel calendario
        this.minSelectableDate = `${tempFirstDate.getFullYear()}${(tempFirstDate.getMonth()).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempFirstDate.getDate().toLocaleString(undefined, {minimumIntegerDigits: 2})}`; // #n

        this.settingsMaxSelectableDate = `${tempLastDate.getFullYear()-1}${(tempLastDate.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempLastDate.getDate()}`; // #n;
        this.settingsMinSelectableDate = "2018";
      }

      // if(this.calendarZoomLevel === "month") { // per ora non utilizzato !? attenzione: da aggiornare con lastAvailableSalesDate !? // #n
      //   this.maxSelectableDate = this.$store.state.outletLastSalesDates.salesLastDayClosedWeek;
      // }

      console.log("max selectable data: ", this.maxSelectableDate);
      console.log("calendar min selectable date: ", this.minSelectableDate);
    }, // /#n

    onShowCalendar() {
      this.calendarHidden === true ? this.calendarHidden = false : this.calendarHidden = true;
    },

    onShowSettings() {
      this.settingsHidden === true ? this.settingsHidden = false : this.settingsHidden = true;
    },

    // getCellCssClass(date) {
    //   let cssClass = '';
    //   const holydays = [[1, 0], [4, 6], [25, 11]];
    //
    //   if (this.isWeekend(date)) { cssClass = 'weekend'; }
    //
    //   holydays.forEach((item) => {
    //     if (date.getDate() === item[0] && date.getMonth() === item[1]) {
    //       cssClass = 'holyday';
    //     }
    //   });
    //
    //   return cssClass;
    // },

    /***************
     * # DATAGRID CHART
     ***************/
    /** ## CONVERTE GLI ORDINALI IN MESI (FOOTFALL, ...) **********/
    fromOrdinalToMonth(ordinalNumber) { /* #globale */
      // console.log("fn fromOrdinalToMonth called");

      if(typeof ordinalNumber != "number") {
        ordinalNumber = Number(ordinalNumber)
      }

      let output = ordinalNumber;
      switch(ordinalNumber) {
        case 1:
          output = "Jan";
          break;
        case 2:
          output = "Feb";
          break;
        case 3:
          output = "Mar";
          break;
        case 4:
          output = "Apr";
          break;
        case 5:
          output = "May";
          break;
        case 6:
          output = "Jun";
          break;
        case 7:
          output = "Jul";
          break;
        case 8:
          output = "Aug";
          break;
        case 9:
          output = "Sep";
          break;
        case 10:
          output = "Oct";
          break;
        case 11:
          output = "Nov";
          break;
        case 12:
          output = "Dec";
          break;
        default:
          output = "-";
      }

      return output;
    },

    /** ## VALORE VISUALIZZATO IN ARGUMENT AXIS **********/
    newArgAxisValue(value) {
      return this.fromOrdinalToMonth(value.value);
    },

    /***************
     * # GENERALE
     ***************/
    onDrawn(e) { /* #globale */
      setTimeout(function () {
        e.component.render()
      }, 1)
    },

    /** ## FILTRO OUTLETS - parte 1: impostazione filtro e chiamate api **********/
    onOutletSelected(e) {
      console.log("selezionato outlet: ", e.value);

      // condizione per pulsante clear: onclick passa null -> defaultOutlet
      let selectedOutletId = e.value == null ? 1 : e.value;

      // ricavo l'outlet dall'id selezionato (in vuex selectedOutlet è l'obj completo)
      let selectedOutlet = this.$store.state.outletsList.filter(outlet => outlet.id == selectedOutletId)[0];

      // invio l'outlet selezionato a vuex
      this.$store.dispatch("dispatchSelectedOutlet", selectedOutlet)

      console.log("selected outlet - vuex: ", this.$store.state.selectedOutlet);

      // al cambio di outlet, reimposto le categorie a 0 (all): non tutti gli outlets hanno le stesse categorie
      this.$store.dispatch("dispatchSelectedCategory", 0);
      // reimpostare anche selectbox categorie
      console.log("on outlet change, reset selected category - vuex: ", this.$store.state.selectedCategory);

      // recupero le categorie dell'outlet e le imposto nel vuex
      this.getDataByCategories();

      // reimposto le ultime date selezionabili in calendario
      this.setMaxSelectableDate();
    },

    /** ## CHIAMATA API FILTRO OUTLETS (anche chiamata iniziale pagina) **********/
    getEffortRateData() {
      console.log("getEffortRateData called");

      // recupero variabili per chiamata da store
      let outletId;
      if(this.$route.name === 'portfolio') {
        outletId = 0;
      } else {
        outletId = this.$store.state.selectedOutlet.id;
      }
      // console.log("api call - outletId: ", outletId);

      let outletsDatesYear = "";
      let outletsDatesMonth = "";

      let yearComp = "";

      // controllo che selected date non sia impostata: se == null, cambiato selezione outlet o primo caricamento pagina: perciò prendo ultima data da outlet
      if(this.$store.state.selectedDate == null) {
        //#n
        outletsDatesYear = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(0, 4); // nel caso arrivi un numero, trasformo in stringa // #n
        console.log("api call - outletsDatesYear", outletsDatesYear);
        // outletsDatesMonth = 10; // temp per test: non ci sono tutti i dati per l'ultimo mese fornito dalle api  // todo: ricordati di passare alla modalità non test
        outletsDatesMonth = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(4, 6); // #n
        console.log("api call - outletsDatesMonth", outletsDatesMonth); // /#n
      } else {
        outletsDatesYear = this.$store.state.selectedDate.slice(0, 4);
        console.log("api call - outletsDatesYear", outletsDatesYear);
        // outletsDatesMonth = 10;
        outletsDatesMonth = this.$store.state.selectedDate.slice(4, 6);
        console.log("api call - outletsDatesMonth", outletsDatesMonth);
      }

      if(this.$store.state.selectedSettings == null) {

        yearComp = outletsDatesYear-1;

      } else {

        yearComp = this.$store.state.selectedSettings;
      }

      ApiCalls.getMainPanel(outletId, outletsDatesYear, outletsDatesMonth, yearComp) /* #componente */
          .then(res => {
            console.log("chiamata api er res: ", res.data);

            // #n
            // imposta dominio in vuex per decidere quali filtri lasciare attivi
            let currentDomain = res.data.metadata.domain;
            this.$store.dispatch("dispatchCurrentDomain", currentDomain);
            // console.log("vuex domain: ", this.$store.state.currentDomain);

            // flag per disabilitare filtri a seconda della pagina
            if(this.$store.state.pagesDisabledFilters.productCategoryFilter.some(domain => domain == currentDomain)) {
              this.productCategoryFilterDisabledByPage = true;

              // se filtro disabilitato, visualizza tutte le categorie merceologiche
              this.$store.dispatch("dispatchSelectedCategory", 0);
            } else {
              this.productCategoryFilterDisabledByPage = false;
            }
            // console.log("productCategoryFilterDisabledByPage: ", this.productCategoryFilterDisabledByPage);
            // /#n

            // salvataggio dati pagina er
            this.effortRateData = res.data; /* #componente */ /************* # COMPONENTE *********************************/

            // fn a parte (cambia per singolo componente): distribuisce dati ai vari componenti
            this.setComponentsData()
            // /fn a parte

          })
          .catch(err => console.log(err.response));
    },


    /** ## CHIAMATA LOCALE FILTRO CLIENT - CATEGORIE **********/
    onProductCategorySelected(e) {

      // imposto selectedCategory in vuex
      console.log("selezionato categoria: ", e.value);
      e.value == null ?
          this.$store.dispatch("dispatchSelectedCategory", 0):
          this.$store.dispatch("dispatchSelectedCategory", e.value);

      console.log("selected category - vuex: ", this.$store.state.selectedCategory);

      // rivalorizzo i dataset dei vari componenti
      this.setComponentsData();
    },

    /** ## VALORIZZAZIONE DATASET DEI VARI COMPONENTI **********/
    setComponentsData() {

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      let result = this.effortRateData.result; /************* # COMPONENTE *********************************/
      let metadata = this.effortRateData.metadata; /************* # COMPONENTE *********************************/
      let selectedProductCategory = this.$store.state.selectedCategory;

      // proprietà che verrà usata per filtrare i tenants a seconda delle categorie di prodotto: se categoria negativa, fitrerò su food / non food, altrimenti su categoria prodotto
      let targetProperty = selectedProductCategory < 0 ? "prodCatClusterId" : "prodCatId";

      // todo: prob, chiamata api salverà dati in variabile, poi chiamerà immediatamente filtro locale per distribuire i dati ai vari componenti; quando categoria prodotto viene chiamata, parte filtro locale; quando outlet o data vengono cambiatai parte chiamata api (che al suo interno ha chiamata a filtro locale)
      /***** ### impostazione dati iniziali bar gauge *****/

      // todo: cambia con filte

      /** ## BAR GAUGE ER **********/
          // bar gauge ref's data (LTM)
      let temp = {};
      temp = result.effortRateClusteringLTM.filter(obj => obj[targetProperty] === selectedProductCategory)[0];
      temp ? this.barGaugeER.dataRef = [temp.goodPercentage, temp.normalPercentage, temp.badPercentage] : [0, 0, 0];
      // console.log("gauge legend ltm: ", this.barGaugeER.dataRef);

      // bar gauge comparison's data (LTMPY)
      temp = result.effortRateClusteringLTMPY.filter(obj => obj[targetProperty] === selectedProductCategory)[0];
      temp ? this.barGaugeER.dataComparison = [temp.goodPercentage, temp.normalPercentage, temp.badPercentage] : [0, 0, 0];
      // // console.log("gauge legend ltmpy: ", this.barGaugeER.dataComparison);


      // // bar gauge dates
      this.refDateLTM = metadata.periods.find(obj => obj.type == "LTM").plainAlt;
      this.refDateLTMShort = metadata.periods.find(obj => obj.type == "LTM").plainAltShort;
      this.comparisonDateLTM = metadata.periodsComparison.find(obj => obj.type == "LTM").plainAlt;
      this.comparisonDateLTMShort = metadata.periodsComparison.find(obj => obj.type == "LTM").plainAltShort;

      // metadata.periods[1] && metadata.periods[1].type === "LTM" ?
      //     this.refDateText = metadata.periods[1].plainAlt :
      //     this.refDateText = "not specified";
      //
      // metadata.periodsComparison[1] && metadata.periodsComparison[1].type === "LTM" ?
      //     this.comparisonDateText = metadata.periodsComparison[1].plainAlt :
      //     this.comparisonDateText = "not specified";

      // bar gauge legend
      this.barGaugeER.legend = metadata.effortRateClusteringThresholds;

      /** ## DATA FOR TEXT VISITORS **********/
      this.textVisitors.dataFilteredByProductCategoryREF = result.footfallSnapshot.filter(el => el[targetProperty] === selectedProductCategory)[0];
      this.textVisitors.dataFilteredByProductCategoryLTM = result.footfallTotalsLTM.filter(el => el[targetProperty] === selectedProductCategory)[0];
      this.textVisitors.dataFilteredByProductCategory = result.footfallTotalsYTD.filter(el => el[targetProperty] === selectedProductCategory)[0];

      // dates
      this.refDateYTD = metadata.periods.find(obj => obj.type == "YTD").plainAlt;
      this.comparisonDateYTD = metadata.periodsComparison.find(obj => obj.type == "YTD").plainAlt;
      this.refDateYTDShort = metadata.periods.find(obj => obj.type == "YTD").plainAltShort;
      this.comparisonDateYTDShort = metadata.periodsComparison.find(obj => obj.type == "YTD").plainAltShort;

      this.refDateREF = metadata.periods.find(obj => obj.type == "REF").plainAlt;
      this.comparisonDateREF = metadata.periodsComparison.find(obj => obj.type == "REF").plainAlt;

      this.refDateLTM = metadata.periods.find(obj => obj.type == "LTM").plainAlt;
      this.comparisonDateLTM = metadata.periodsComparison.find(obj => obj.type == "LTM").plainAlt;

      //Dates
      metadata.periods[0] && metadata.periods[0].type === "REF" ? this.refDateText = metadata.periods[0].plain : this.refDateText = "not specified";
      metadata.periodsComparison[0] && metadata.periodsComparison[0].type === "REF" ? this.comparisonDateText = metadata.periodsComparison[0].plain : this.comparisonDateText = "not specified";

      //Occupancy Units
      this.barGaugeEROccupancy.dataRef = (result.occupancySnapshot.occupancyREFPY && result.occupancySnapshot.occupancyREF) ? [result.occupancySnapshot.occupancyREFPY, result.occupancySnapshot.occupancyREF] : [0,0];
      this.fullstackedbar.leasedUnitsREF = result.occupancySnapshot.leasedUnitsREF ?? 0;
      this.fullstackedbar.vacantUnitsREF = result.occupancySnapshot.vacantUnitsREF ?? 0;
      this.fullstackedbar.totaleREF = this.fullstackedbar.leasedUnitsREF + this.fullstackedbar.vacantUnitsREF;
      this.fullstackedbar.leasedUnitsREFPY = result.occupancySnapshot.leasedUnitsREFPY ?? 0;
      this.fullstackedbar.vacantUnitsREFPY = result.occupancySnapshot.vacantUnitsREFPY ?? 0;
      this.fullstackedbar.totaleREFPY = this.fullstackedbar.leasedUnitsREFPY + this.fullstackedbar.vacantUnitsREFPY;

      this.refDateTextGauge = this.refDateText + " (" + this.barGaugeEROccupancy.dataRef[1].toLocaleString("it-IT") + "%)";
      this.comparisonDateTextGauge = this.comparisonDateText + " (" + this.barGaugeEROccupancy.dataRef[0].toLocaleString("it-IT") + "%)";

      this.comparisonDateOccupancy = loc.OCCUPANCY.LEASED, // necessario perché altrimenti il componente non riceve i dati in tempo e non compare al primo caricamento
          this.refDateOccupancy = loc.OCCUPANCY.VACANT, // necessario perché altrimenti il componente non riceve i dati in tempo e non compare al primo caricamento

          this.fullstackedbar.dataSource = [
            {
              units: this.comparisonDateText,
              leasedUnits: this.fullstackedbar.leasedUnitsREFPY,
              vacantUnits: this.fullstackedbar.vacantUnitsREFPY,
              total: this.fullstackedbar.totaleREFPY
            },
            {
              units: this.refDateText,
              leasedUnits: this.fullstackedbar.leasedUnitsREF,
              vacantUnits: this.fullstackedbar.vacantUnitsREF,
              total: this.fullstackedbar.totaleREF,
            }];
      //::end Occupancy Units

      //Net Sales
      this.cloumnStatistic.dataFilteredByProductCategory = result.salesSnapshot.filter(el => el[targetProperty] === selectedProductCategory)[0];
      this.cloumnStatistic.dataFilteredByProductCategoryYTD = result.salesTotalsYTD.filter(el => el[targetProperty] === selectedProductCategory)[0];
      this.cloumnStatistic.dataFilteredByProductCategoryLTM = result.salesTotalsLTM.filter(el => el[targetProperty] === selectedProductCategory)[0];

      //NetSales
      this.cloumnStatistic.netSales.salesREFPY = this.cloumnStatistic.dataFilteredByProductCategory.salesREFPY ?? 0;
      this.cloumnStatistic.netSales.salesREF = this.cloumnStatistic.dataFilteredByProductCategory.salesREF ?? 0;
      this.cloumnStatistic.netSales.varSales = this.cloumnStatistic.dataFilteredByProductCategory.varSales ?? 0;

      //NetSalesSqm
      this.cloumnStatistic.netSalesSqm.salesSqmREFPY = this.cloumnStatistic.dataFilteredByProductCategory.salesSqmREFPY ?? 0;
      this.cloumnStatistic.netSalesSqm.salesSqmREF = this.cloumnStatistic.dataFilteredByProductCategory.salesSqmREF ?? 0;
      this.cloumnStatistic.netSalesSqm.varSalesSqm = this.cloumnStatistic.dataFilteredByProductCategory.varSalesSqm ?? 0;

      //AvegTicket
      this.cloumnStatistic.avegTicket.avgTicketREFPY = this.cloumnStatistic.dataFilteredByProductCategory.avgTicketREFPY ?? 0;
      this.cloumnStatistic.avegTicket.avgTicketREF = this.cloumnStatistic.dataFilteredByProductCategory.avgTicketREF ?? 0;
      this.cloumnStatistic.avegTicket.varAvgTicket = this.cloumnStatistic.dataFilteredByProductCategory.varAvgTicket ?? 0;

      //SpendPerVisitor
      this.cloumnStatistic.spendPerVisitor.spvREFPY = this.cloumnStatistic.dataFilteredByProductCategory.spvREFPY ?? 0;
      this.cloumnStatistic.spendPerVisitor.spvREF = this.cloumnStatistic.dataFilteredByProductCategory.spvREF ?? 0;
      this.cloumnStatistic.spendPerVisitor.varSPV = this.cloumnStatistic.dataFilteredByProductCategory.varSPV ?? 0;

      //OpenDays
      this.cloumnStatistic.openDays.openDaysREFPY = this.cloumnStatistic.dataFilteredByProductCategory.openDaysREFPY ?? 0;
      this.cloumnStatistic.openDays.openDaysREF = this.cloumnStatistic.dataFilteredByProductCategory.openDaysREF ?? 0;
      //this.cloumnStatistic.spendPerVisitor.varSPV = this.cloumnStatistic.dataFilteredByProductCategory.varSPV ?? 0;

      //CrRetail
      this.cloumnStatistic.crRetail.crREFPY = this.cloumnStatistic.dataFilteredByProductCategory.crREFPY ?? 0;
      this.cloumnStatistic.crRetail.crREF = this.cloumnStatistic.dataFilteredByProductCategory.crREF ?? 0;
      this.cloumnStatistic.crRetail.varCR = this.cloumnStatistic.dataFilteredByProductCategory.varCR ?? 0;
      //end:: Net Sales

      // Tenants
      this.tenantsDatagridData = {
        openingTenants: [...result.openingTenants],
        closingTenants: [...result.closingTenants],
        indemnitySituations: [...result.indemnitySituations],
        tboImmediateSituations: [...result.tboImmediateSituations],
        tboBreakDate: metadata.periods.find(el => el.type == "NXT").plain
      }
      console.debug("tenantsDatagridData: ", this.tenantsDatagridData)

      // Lof style
      this.outletLofStyle = this.$store.state.outletsList.find(outlet => outlet.id === 0)

      this.pageDataReady = true;

      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/
    },

    /******************************************************************************************************
     * # COMPONENTE - INIZIO
     **/

    /***************
     * # BAR GAUGE
     ***************/
    customizeText({valueText}) {
      return `${valueText} %`;
    },

    customizeBarGaugeLegendText({text, item}) {
      if(item.index == 0) {
        return `Good (${(text)}%)`
      }

      if(item.index == 1) {
        return `Normal (${(text)}%)`
      }

      if(item.index == 2) {
        return `Bad (${(text)}%)`
      }
    },

    /***************
     * # DATAGRID
     ***************/
    /** ## EXPORT BUTTON **********/
    onExportingDatagridEr(e) { /* #globale */
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = {name: 'Arial', size: 12};
          excelCell.alignment = {horizontal: 'left'};
        }
      }).then(function () {
        workbook.xlsx.writeBuffer()
            .then(function (buffer) {
              saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'DataGrid.xlsx');
            });
      });
      e.cancel = true;
    },

    /***************
     * # SCATTER
     ***************/
    /** ## RESET ZOOM BUTTON **********/
    saveScatterErInstance1(e) {
      this.scatterErInstance1 = e.component;
    },

    resetScatterErZoom1() {
      console.log(this.scatterErInstance1)
      this.scatterErInstance1.resetVisualRange()
      // this.scatterErInstance.ActualAxisY.ActualVisualRange.SetAuto();
    },

    saveScatterErInstance2(e) {
      this.scatterErInstance2 = e.component;
    },

    resetScatterErZoom2() {
      console.log(this.scatterErInstance2)
      this.scatterErInstance2.resetVisualRange()
      // this.scatterErInstance.ActualAxisY.ActualVisualRange.SetAuto();
    },

    /** ## TOOLTIP **********/

    //  DATAGRID WORST EFFORT RATE
    //   sortDataWorstER(data) {
    //     return data.sort((a,b) => b.effortRatePercentLTM - a.effortRatePercentLTM);
    //   },
  },

  /**
   * # /COMPONENTE - FINE
   ******************************************************************************************************/

  mounted() {
    this.prova();
    console.log("style", this.getOutletStyle)
  },

  beforeCreate() {
    // this.outletId = this.$store.getters.getSelectedOutlet.id; // #1
  },

  async created() {
    auth.getUser().then((e) => {
      if (e.data) {
        // User is authenticated
        console.debug("_user data", e.data)
      }
    });

    // -- primo accesso all'app

    // CHIAMATA API MAIN.JS INIZIALE
    // 0. main.js fa le varie chiamate api per prendere le liste dei filtri (store - e data -, categorie) e li salva in vuex -> ho i dati per popolare i filtri
    //    (_ controllo localStorage per outlet)
    //    (_ salva dati in local storage)

    // -- primo accesso alla pagina
    // "CHIAMATA LOCALE" A VUEX PER PRENDERE I FILTRI ->
    // 1. prendo dati per popolare i filtri da vuex via getters: store, prodCatId/, dates -> popolo i filtri

    // CHIAMATA API PAGINA ER INIZIALE -> fn api
    // 2. prendo i filtri selezionati da vuex (default per primo accesso: prodCatId = 0, store = 1, data = data corrente (mese, ... dipende da pagina))
    // 3. faccio chiamata api con filtri selezionati (punto 2) per popolare i componenti;
    //    _ salvo i dati della chiamata api in una variabile per poterli usare con il filtro lato client
    //    _ popolo i data-set dei vari componenti con la variabile appena valorizzata

    // -- / fine primo accesso alla pagina

    // CHIAMATA LOCALE FILTRO CLIENT (CATEGORIE) -> fn client
    // 4. al click su filtro categorie, chiamo fn client
    // 5. fn:
    //    _ salva il filtro selezionato in vuex
    //    _ prendo i dati er salvati nella variabile locale, li filtro e li salvo nei dataset dei vari componenti

    // CHIAMATA API FILTRO DATA / OUTLET
    // 6. al click su filtro data / outlet,
    // 7. salvo filtro selezionato su vuex -> fn (una commit, action per variabile o una per tutte e 3?)
    // 8. chiamo fn api

    /***************
     * # LOCALIZZAZIONE
     ***************/
    loadMessages(itMessages);
    locale(this.locale);

    // #todo: adottare sintassi con params xé + chiara?

    console.log("vuex: ", this.$store);

    /***************
     * # 0: main.js
     ***************/
    await this.getOutletsData();

  },



};

// todo: chiedere perché componenti scoped prevengono alcune regole css (es: css-class in dxcolumn
</script>

<style lang="scss" scoped>
/***************
* # FILTRI
***************/
//
//.filterContainer { /* #globale */
//  padding: 10px;
//  display: flex;
//  justify-content: space-between;
//  flex-direction: row !important;
//  align-items: center;
//
//  //h2 {
//  //  font-family: Nunito, sans-serif;
//  //  font-size: 26px;
//  //  font-weight: 600;
//  //
//  //  @media screen and (max-width: 1024px){
//  //    font-size: 22px;
//  //  }
//  //}
//
//  //#categoriesListProv {
//  //  text-align: center;
//  //  padding: 7px;
//  //  border: none;
//  //  border-radius: 14px;
//  //  appearance: none;
//  //
//  //  color: #AA192F;
//  //  background-color: #FDE5E8;
//  //  //width: 117px;
//  //  //height: 24px;
//  //}
//
//}

/** ## CALENDAR **********/


/***************
* # BAR GAUGE
***************/
.gauge {
  //height: 232px;
  height: 300px;
}

.stackedbar, .small-gauge {
  //height: 232px;
  height: 260px;

}

/***************
* # SCATTER - DATAGRID
***************/
.scatterChart, .datagrid {
  height: 413px;
}


/***************
* # COMPONENT WORST PERFORMER - DATAGRID
***************/

/*** ***** #!! ATTENZIONE: alcuni elementi css non vengono presi xé il componente è scoped
 -> passarli in custom-style.css !! */

//.tenant-column {
//  color: orange !important;
//  font-size: 50px !important;
//  background-color: green !important;
//}
//
//
//.cell-highlighted {
//  background-color: green !important;
//  color: orange !important;
//  font-style: italic !important;
//  font-size: 25px !important;
//}


// boh? #check
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
// /boh? #check


@media screen and (max-width: 1024px) {
  .gauge { // #check: da mettere in custom-style? Pensaci dopo aver fatto tutte le pagine.
    //height: 175px;
    height: 240px;
  }

  .stackedbar, .small-gauge {
    //height: 175px;
    height: 200px;
  }

  .scatterChart, .datagrid { // #check: da mettere in custom-style? Pensaci dopo aver fatto tutte le pagine.
    height: 313px;
  }
}
@media screen and (max-width: 768px) {

  .stackedbar, .small-gauge {
    height: 180px;
  }

}
</style>
